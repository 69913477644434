export const TRANSLATE = {
  EXPORT_CSV: "Csv出力",
  EXPORT: "出力",
  FROM_DATE: "期間指定　From",
  TO_DATE: "期間指定　To",
  STORE_NUMBER: "店舗",
  SURVEY_CATEGORY: "調査の種類",
  PHASE: "フェーズ",
  ALL: "全",
  FROM_DATE_REQUIRED: "期間指定　Fromを入力してください。",
  TO_DATE_REQUIRED: "期間指定　To日を入力してください。",
  BACK: "戻る",
  OK: "終了",
  NO_DATA: "ダウンロードしたデータがありません",
  ERROR_MESSAGE:
    "リクエストの処理中に問題が発生しました。もう一度お試しください。",
  EXPORT_SUCCESS: "ファイルのエクスポートに成功しました",
  JOB_STATUS_RUNNING: "Running",
  JOB_STATUS_FAILED: "Failed",
  JOB_STATUS_DONE: "Done",
  JOB_RESULT_NO_DATA: "NotFoundSurvey",
  TIME_PERIOD_CALL_JOB: 15000,
};
