import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "configs/axiosInstance";
import { DEFAULT_PHASE_NUMBER } from "constants/common";
import { TFilterSurveyRequest } from "features/statistic/statisticApi";
import { TMemberDetail } from "model/member";
import {
  ESurveyCategory,
  ESurveyPhase,
  TQuestion,
  TQuestionCreation,
  TQuestionForSubmit,
  TQuestionOption,
} from "model/survey";
import { TResponse } from "types/service";

const surveyApi = {
  getSurveyQuestions: async (phase: number) => {
    let surveyCategory: number | undefined;
    let phaseNumber: number | undefined;
    switch (phase) {
      case ESurveyPhase.GYM_PHASE_1:
      case ESurveyPhase.GYM_PHASE_2:
        surveyCategory = ESurveyCategory.GYM;
        phaseNumber = phase;
        break;
      case ESurveyPhase.JUNIOR_PHASE:
        surveyCategory = ESurveyCategory.JUNIOR_SCHOOL;
        phaseNumber = DEFAULT_PHASE_NUMBER;
        break;
      case ESurveyPhase.ADULT_PHASE:
        surveyCategory = ESurveyCategory.ADULT_SCHOOL;
        phaseNumber = DEFAULT_PHASE_NUMBER;
        break;
    }
    const url = "get-survey";
    const config: AxiosRequestConfig = {
      params: { surveyCategory, phaseNumber },
    };
    const response: TResponse<TQuestion[]> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  submitSurveyAndCreateMember: async (data: TSaveSurveyProps) => {
    const url = "create-survey";
    const response: TResponse<TSaveSurveyRes> = await axiosInstance.post(
      url,
      data
    );

    return response;
  },

  submitSurveyIntermediate: async (data: TSaveSurveyIntermediateProps) => {
    const url = "create-survey-phase-two";

    const response: TResponse = await axiosInstance.post(url, data);

    return response;
  },

  getSurveyDetailByPhaseNumber: async (params: TGetSurveyDetailReq) => {
    const url = "get-survey-by-member-id";

    const config: AxiosRequestConfig = {
      params,
    };

    const response: TResponse<TGetSurveyDetailRes[]> = await axiosInstance.get(
      url,
      config
    );

    return response;
  },

  getSurveyDetailByMember: async (memberId: string) => {
    const url = "get-all-survey-split-phase";

    const config: AxiosRequestConfig = {
      params: { memberId },
    };

    const response: TResponse<TGetSurveyDetailByPhaseRes> =
      await axiosInstance.get(url, config);

    return response;
  },

  updateSurvey: async ({ memberId, questions }: TUpdateSurveyProps) => {
    const url = "update-survey";

    const config: AxiosRequestConfig = {
      params: { memberId },
    };

    const response: TResponse = await axiosInstance.put(url, questions, config);

    return response;
  },

  updateSurveyMaster: async (questions: TQuestionCreation[]) => {
    const url = "update-question";

    const response: TResponse = await axiosInstance.post(url, questions);

    return response;
  },

  exportSurvey: async (request: TFilterSurveyRequest) => {
    const url = "export-survey";

    const response: TResponse = await axiosInstance.post(url, request);

    return response;
  },

  getCsvExportByJod: async (jobId: string) => {
    const url = `job/${jobId}`;
    const response: TResponse = await axiosInstance.get(url);
    return response;
  },
};

export type TSaveSurveyProps = {
  memberAxtosId: string;
  questions: TQuestionForSubmit[];
};

export type TUpdateSurveyProps = {
  memberId: string;
  questions: TQuestionForSubmit[];
};

export type TSaveSurveyIntermediateProps = {
  memberId: string;
  questions: TQuestionForSubmit[];
};

export type TSaveSurveyRes = {
  surveys: TQuestionForSubmit[];
  member: TMemberDetail;
};

export type TGetSurveyDetailReq = {
  memberId: string;
  phaseType: ESurveyPhase;
  surveyCategory?: number;
};

export type TGetSurveyDetailRes = {
  memberId: string;
  question: TQuestion;
  answer: TQuestionOption[];
  otherAnswer: null | string;
  phaseNumber: ESurveyPhase;
};

export type TGetSurveyDetailByPhaseRes = {
  phaseBeginner?: TGetSurveyDetailRes[];
  phaseBeginnerUpdatedAt?: string;
  phaseIntermediate?: TGetSurveyDetailRes[];
  phaseIntermediateUpdatedAt?: string;
  junior?: TGetSurveyDetailRes[];
  juniorUpdatedAt?: string;
  adult?: TGetSurveyDetailRes[];
  adultUpdatedAt?: string;
};

export default surveyApi;
