import { Button, Col, Form, Modal, Row } from "antd";
import {
  DatePickerStyled,
  ExportSurveyButtonStyled,
  SelectStyled,
} from "./ExportSurveyButton.styled";
import { TRANSLATE } from "./ExportSurveyButton.translate";
import { useState } from "react";
import surveyApi from "features/survey/surveyApi";
import { useAppLoading, useAppModal } from "contexts";
import { DownOutlined } from "@ant-design/icons";
import { TFilterSurveyRequest } from "features/statistic/statisticApi";
import dayjs from "dayjs";
import {
  ADULT_SCHOOL_SURVEY,
  DATE_FORMAT_API,
  DEFAULT_PHASE_NUMBER,
  GYM_SURVEY,
  JUNIOR_SCHOOL_SURVEY,
} from "constants/common";
import { RangePickerProps } from "antd/es/date-picker";
import { DEFAULT_MODAL_PROPS } from "constants/common";
import { useScrollLock } from "hooks";
import { ESurveyCategory } from "model/survey";

const initialValues: TFilterSurveyRequest = {
  fromDate: "",
  toDate: "",
  storeNumber: [""],
  surveyCategory: ESurveyCategory.GYM,
  phase: "1", // default value is phase 1
};

const FILTER_SURVEY_FIELD = {
  FROM_DATE: {
    label: TRANSLATE.FROM_DATE,
    name: "fromDate",
  },
  TO_DATE: {
    label: TRANSLATE.TO_DATE,
    name: "toDate",
  },
  STORE_NUMBER: {
    label: TRANSLATE.STORE_NUMBER,
    name: "storeNumber",
  },
  SURVEY_CATEGORY: {
    label: TRANSLATE.SURVEY_CATEGORY,
    name: "surveyCategory",
  },
  PHASE: {
    label: TRANSLATE.PHASE,
    name: "phase",
  },
};

const CLASS_NAME_SELECT_BEHIND_HEADER = "common__select--z-index-behind-header";

interface ISelectOption {
  value: string | number;
  label: string;
}

const phaseOptions = [
  { value: "1", label: "フェーズ1" },
  { value: "2", label: "フェーズ2" },
];

const surveyCategoryOptions = [
  { value: ESurveyCategory.GYM, label: GYM_SURVEY },
  { value: ESurveyCategory.ADULT_SCHOOL, label: ADULT_SCHOOL_SURVEY },
  { value: ESurveyCategory.JUNIOR_SCHOOL, label: JUNIOR_SCHOOL_SURVEY },
];

type TProps = {
  storeOptions: ISelectOption[];
};

function ExportSurveyButton(props: TProps) {
  const { storeOptions } = props;
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisablePhase, setIsDisablePhase] = useState(false);
  const { startLoading, stopLoading } = useAppLoading();
  const { appModal } = useAppModal();
  useScrollLock(openModal);

  const showErrorModal = (title: string) => {
    appModal.error({
      ...DEFAULT_MODAL_PROPS.CONFIRM,
      title,
      okText: TRANSLATE.BACK,
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  async function checkJobStatus(jobId: string) {
    let isRunning = true;

    while (isRunning) {
      const responseJob = await surveyApi.getCsvExportByJod(jobId);

      const downloadCsv = document.createElement("a");

      switch (responseJob.data.status) {
        case TRANSLATE.JOB_STATUS_RUNNING:
          await new Promise((resolve) =>
            setTimeout(resolve, TRANSLATE.TIME_PERIOD_CALL_JOB)
          );
          break;
        case TRANSLATE.JOB_STATUS_FAILED:
          isRunning = false;
          stopLoading();
          setIsLoading(false);
          if (responseJob.data.result === TRANSLATE.JOB_RESULT_NO_DATA) {
            showErrorModal(TRANSLATE.NO_DATA);
          } else {
            showErrorModal(TRANSLATE.ERROR_MESSAGE);
          }
          break;
        case TRANSLATE.JOB_STATUS_DONE:
          stopLoading();
          setIsLoading(false);
          isRunning = false;
          downloadCsv.href = responseJob.data.result;
          downloadCsv.click();
          break;
        default:
          break;
      }
    }
  }
  const handleExport = async (values: TFilterSurveyRequest) => {
    startLoading();
    setIsLoading(true);

    try {
      const fromDate = values.fromDate
        ? dayjs(values.fromDate).format(DATE_FORMAT_API)
        : "";
      const toDate = values.toDate
        ? dayjs(values.toDate).format(DATE_FORMAT_API)
        : "";
      const surveyCategory = values.surveyCategory;
      const phase =
        surveyCategory === ESurveyCategory.GYM
          ? values.phase || ""
          : DEFAULT_PHASE_NUMBER.toString();
      const storeNumber =
        values.storeNumber[0] !== "" ? values.storeNumber : []; // clear default value = ""

      const response: any = await surveyApi.exportSurvey({
        fromDate,
        toDate,
        surveyCategory,
        phase,
        storeNumber,
      });

      if (response) {
        await new Promise((resolve) =>
          setTimeout(resolve, TRANSLATE.TIME_PERIOD_CALL_JOB)
        );
        checkJobStatus(response.jobId);
      }
    } catch (error) {
      showErrorModal(TRANSLATE.ERROR_MESSAGE);
    }
  };

  const handleChangeStoreNumber = (values: any) => {
    // if select all then clear other option
    if (values[values.length - 1] === "" || values.length === 0) {
      form.setFieldsValue({
        storeNumber: [""],
      });
    } else {
      const filteredValues = values.filter((value: string) => value !== "");
      form.setFieldsValue({
        storeNumber: filteredValues,
      });
    }
  };

  const handleChangeSurveyCategory = (value: unknown) => {
    if (value !== ESurveyCategory.GYM) {
      setIsDisablePhase(true);
    } else {
      setIsDisablePhase(false);
    }
    form.setFieldsValue({
      [FILTER_SURVEY_FIELD.PHASE.name]: initialValues.phase,
    });
  };

  const disabledFromDate: RangePickerProps["disabledDate"] = (current) => {
    const toDate = dayjs(form.getFieldValue(FILTER_SURVEY_FIELD.TO_DATE.name));
    // Can not select days after today or after "toDate"
    return current.isAfter(dayjs(), "day") || current.isAfter(toDate, "day");
  };

  const disabledToDate: RangePickerProps["disabledDate"] = (current) => {
    const fromDate = dayjs(
      form.getFieldValue(FILTER_SURVEY_FIELD.FROM_DATE.name)
    );
    // Can not select days after today or before "fromDate"
    return current.isAfter(dayjs(), "day") || current.isBefore(fromDate, "day");
  };

  // Custom validation function for fromDate and toDate
  const validateDateRange = async () => {
    // Clear errors for both fromDate and toDate
    form.setFields([
      {
        name: FILTER_SURVEY_FIELD.FROM_DATE.name,
        errors: [],
      },
      {
        name: FILTER_SURVEY_FIELD.TO_DATE.name,
        errors: [],
      },
    ]);

    return Promise.resolve();
  };

  return (
    <ExportSurveyButtonStyled>
      <Button
        type="default"
        className="btn-export"
        onClick={() => setOpenModal(true)}
      >
        {TRANSLATE.EXPORT_CSV}
      </Button>
      <Modal
        centered
        open={openModal}
        maskStyle={{ background: "rgba(23, 24, 29, 0.9)" }}
        width={621}
        onCancel={() => {
          setOpenModal(false);
          setIsDisablePhase(false);
          form.resetFields();
        }}
        title={TRANSLATE.EXPORT_CSV}
        footer={null}
        maskClosable={false}
        zIndex={1}
      >
        <Form
          colon={false}
          onFinish={handleExport}
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <Row gutter={[8, 0]} wrap>
            <Col span={12}>
              <Form.Item
                label={FILTER_SURVEY_FIELD.FROM_DATE.label}
                name={FILTER_SURVEY_FIELD.FROM_DATE.name}
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        !value &&
                        form.getFieldValue(FILTER_SURVEY_FIELD.TO_DATE.name)
                      ) {
                        return Promise.reject(
                          `${TRANSLATE.FROM_DATE_REQUIRED}`
                        );
                      }
                      return validateDateRange();
                    },
                  },
                ]}
              >
                <DatePickerStyled disabledDate={disabledFromDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={FILTER_SURVEY_FIELD.TO_DATE.label}
                name={FILTER_SURVEY_FIELD.TO_DATE.name}
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        !value &&
                        form.getFieldValue(FILTER_SURVEY_FIELD.FROM_DATE.name)
                      ) {
                        return Promise.reject(`${TRANSLATE.TO_DATE_REQUIRED}`);
                      }
                      return validateDateRange();
                    },
                  },
                ]}
              >
                <DatePickerStyled disabledDate={disabledToDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]} wrap>
            <Col span={8}>
              <Form.Item
                label={FILTER_SURVEY_FIELD.STORE_NUMBER.label}
                name={FILTER_SURVEY_FIELD.STORE_NUMBER.name}
              >
                <SelectStyled
                  mode="multiple"
                  options={[
                    { label: TRANSLATE.ALL, value: "" },
                    ...storeOptions,
                  ]}
                  notFoundContent={<div />}
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  suffixIcon={<DownOutlined />}
                  showSearch
                  popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                  onChange={handleChangeStoreNumber}
                  maxTagCount={"responsive"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={FILTER_SURVEY_FIELD.SURVEY_CATEGORY.label}
                name={FILTER_SURVEY_FIELD.SURVEY_CATEGORY.name}
              >
                <SelectStyled
                  options={[...surveyCategoryOptions]}
                  notFoundContent={<div />}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  suffixIcon={<DownOutlined />}
                  showSearch
                  popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                  onChange={handleChangeSurveyCategory}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={FILTER_SURVEY_FIELD.PHASE.label}
                name={FILTER_SURVEY_FIELD.PHASE.name}
              >
                <SelectStyled
                  options={[...phaseOptions]}
                  notFoundContent={<div />}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  suffixIcon={<DownOutlined />}
                  showSearch
                  popupClassName={CLASS_NAME_SELECT_BEHIND_HEADER}
                  disabled={isDisablePhase}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" align="bottom">
            <Button
              type="primary"
              className="btn-export"
              htmlType="submit"
              onClick={() => setOpenModal(true)}
              disabled={isLoading}
            >
              {TRANSLATE.EXPORT}
            </Button>
          </Row>
        </Form>
      </Modal>
    </ExportSurveyButtonStyled>
  );
}

export { ExportSurveyButton };
